<template>
  <div>
    
  </div>
</template>

<script>
export default {
  name: 'VideoIntro'
}
</script>

<style>

</style>